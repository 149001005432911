import Axios from '../services/axios';

const PATH = 'customers';

export function updateCompany(data: object) {
  return Axios.post(`${PATH}/company`, data);
}

export function updateCustomer(contractId: string, data: object) {
  return Axios.post(`${PATH}?contractId=${contractId}`, data);
}
