import Axios from '../services/axios';

const PATH = 'customer/general';

export function getProvinces() {
  return Axios.get(`provinces`);
}

export function getDistrict(provinceId: string) {
  return Axios.get(`districts?provinceId=${provinceId}`);
}

export function getWard(districtId: string) {
  return Axios.get(`wards?districtId=${districtId}`);
}

export function getCompany() {
  return Axios.get(`companies`);
}
